.dot1, .dot2, .dot3 {
    background: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 10px;
  }
  .dot1 {
    animation: jump 1s -0.4s linear infinite;
  }
  .dot2 {
      animation: jump 1s -0.2s linear infinite;
  }
  .dot3 {
      animation: jump 1s linear infinite;
  }
  @keyframes jump {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 20% {
        -webkit-transform: scale(1);
        transform: scale(1);
    } 40% { 
      -webkit-transform: scale(2);
      transform: scale(2);
    } 60% { 
      -webkit-transform: scale(2);
      transform: scale(2);
    } 80% {
        -webkit-transform: scale(1);
        transform: scale(1);
    } 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
  }