
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: Nunito;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px transparent;
  border-radius: 3px;
}

*::-webkit-scrollbar-thumb {
  background-color: #00000033;
  border-radius: 12px;
}

* {
  scrollbar-color: #00000033 transparent;
  scrollbar-width: thin;

}

input::-webkit-inner-spin-button{
  display: none;
}

input[type=number] {
  -moz-appearance:textfield;
}
