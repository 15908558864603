.rdrCalendarWrapper.rdrDateRangeWrapper {
    width: 100%;
}

.rdrMonth {
    width: 100%;
    padding: 0;
}

.rdrWeekDay {
    width: auto;
    font-size: 12px;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
    display: none;
}

.rdrDayToday .rdrDayNumber span:after {
    display: none;
}

.rdrCalendarWrapper {
    color: #000000;
    font-size: 16px;
}

.rdrDayNumber {
    font-weight: 400;
    z-index: 2;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #ffffff;
}

.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    left: 2px;
}

.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    left: 0;
}

.rdrStartEdge.rdrEndEdge {
    border-radius: 32px;
    left: 2px;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    left: 2px;
}

.rdrStartEdge {
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    left: 2px;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background: rgba(79, 90, 111, 1);
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 38px;
}

.rdrDayHovered .rdrDayInPreview,
.rdrDayHovered .rdrDayStartPreview,
.rdrDayHovered .rdrDayEndPreview {
    border-radius: 32px;
    background: #DEDEDE;
}

.rdrInRange + .rdrDayInPreview,
.rdrInRange + .rdrDayStartPreview,
.rdrInRange + .rdrDayEndPreview,
.rdrStartEdge + .rdrDayInPreview,
.rdrStartEdge + .rdrDayStartPreview,
.rdrStartEdge + .rdrDayEndPreview,
.rdrEndEdge + .rdrDayInPreview,
.rdrEndEdge + .rdrDayStartPreview,
.rdrEndEdge + .rdrDayEndPreview {
    background: transparent;
}

.rdrStartEdge {
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    left: 2px;
}

.rdrInRange {
    border-radius: 0;
}

.rdrEndEdge {
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    right: 2px;
}

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    border-right-width: 1px;
    right: 0;
}

.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    right: 2px;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
    background: transparent;
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    bottom: 3px;
    pointer-events: none;
    border: none;
}

.rdrDateDisplayItemActive {
    border: none;
}

.rdrDayHovered {
    border: none;
}

.rdrDayStartPreview {
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    left: 2px;
}

.rdrDayInPreview {
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.rdrDayEndPreview {
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
    right: 0;
}

.rdrDateDisplayItemActive input {
    color: black;
}

.rdrNextPrevButton {
    border-radius: 50%;
    background-color: transparent;
    height: 32px;
    width: 32px;
    position: relative;
}

.rdrNextPrevButton.rdrPprevButton:after,
.rdrNextPrevButton.rdrNextButton:after {
    content: "";
    position: absolute;
    top: 8px;
    width: 11px;
    height: 17px;
}

.rdrNextPrevButton.rdrPprevButton:after {
    background: url(src/assets/images/left-arrow.svg) no-repeat;
    left: 10px;
}

.rdrNextPrevButton.rdrNextButton:after {
    background: url(src/assets/images/right-arrow.svg) no-repeat;
    right: 10px;
}

.rdrNextPrevButton i {
    display: none;
}

.rdrNextPrevButton:hover {
    background-color: #DEDEDE;
}




